import { map } from 'lodash'
import Link from 'next/link'

import { ICategoryTeaser } from '../../models/landingpage'
import { UrlBuilder } from '../helper/picture-tag'

interface CategoryTeaserProps {
  data?: ICategoryTeaser
}

const CategoryTeaser = ({ data }: CategoryTeaserProps) => {
  if (!data) {
    return <></>
  }
  return (
    <div className={`container mx-auto py-4`}>
      <p className={`text-3xl font-bold italic text-center mb-4 font-swDisp`}>
        {data.ComponentTitle}
      </p>
      <div className="columns-2 md:columns-3 lg:columns-3">
        {map(data.CategoryTeaserElemets, (categoryTeaserElement, index) => (
          <div key={index} className={`mb-4 relative`}>
            <Link href={`/kategorie/${categoryTeaserElement?.Category?.Slug}`}>
              <a>
                <p
                  className={`absolute bottom-0 right-0 m-2 z-[99] text-xxl font-swDisp font-bold text-white italic `}
                >
                  {categoryTeaserElement?.Title}
                </p>
                {categoryTeaserElement.Image?.image && (
                  <img
                    src={UrlBuilder(
                      categoryTeaserElement.Image?.image?.fullpath,
                      500,
                      'png',
                      false,
                      false,
                      true
                    )}
                    className=" h-auto max-w-full"
                  />
                )}
              </a>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CategoryTeaser
